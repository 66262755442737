export const SCREEN_SIZE = {
	MOBILE: 480,
	TABLET: 768,
	DESKTOP: 1024,
};

export const DEVICE = {
	MOBILE: `(min-width: ${SCREEN_SIZE.MOBILE}px)`,
	TABLET: `(min-width: ${SCREEN_SIZE.TABLET}px)`,
	DESKTOP: `(min-width: ${SCREEN_SIZE.DESKTOP}px)`,
};
