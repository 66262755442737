import { useTheme } from "styled-components";
import { MainButton } from "../../../../../components/MainButton";
import MainInput from "../../../../../components/MainInput";
import { OutlineButton } from "../../../../../components/OutlineButton";
import { BackgroundContainer, ContentContainer, DeleteFamilyNameContainer, EmptyFamilyNameText, FamilyNameButtonContainer, FamilyNameInputContainer, FamilyNamesContainer, FooterInfosContainer, InputContainer, Subtitle, Title, TrashIcon } from "./styles"
import trash from "../../../../../assets/images/admin/trash.png";
import { CreateRsvpRequest } from "../../../../../types/api/invitedFamily";
import { useCallback, useEffect, useState } from "react";
import { formatPhoneNumber, removePhoneMask } from "../../../../../utils/TextFormatter";

interface NewRsvpModalProps {
    isVisible: boolean;
    onClose: () => void;
    onSaveNewRsvp: (newRsvp: CreateRsvpRequest) => void;
}

const initialRsvp = {
    inviteName: "",
    telephoneNumber: "",
    names: [],
}

export const NewRsvpModal = ({ isVisible, onClose, onSaveNewRsvp }: NewRsvpModalProps) => {

    const theme = useTheme();
    const [newRsvp, setNewRsvp] = useState<CreateRsvpRequest>(initialRsvp);
    const [nameError, setNameError] = useState("");
    const [numberError, setNumberError] = useState("");
    const [nameErrors, setNameErrors] = useState<string[]>([]);

    useEffect(() => {
        setNewRsvp(initialRsvp);
    }, [isVisible]);

    const getFamilyContent = () => {
        if (newRsvp.names.length > 0) {
            return newRsvp.names.map((personName, index) => {
                return (
                    <FamilyNameInputContainer key={index}>
                        <MainInput
                            value={personName}
                            onChange={(newName) => {
                                setNewRsvp({
                                    ...newRsvp,
                                    names: newRsvp.names.map((name, i) => {
                                        if (i === index) {
                                            return newName;
                                        }
                                        return name;
                                    })
                                })
                            }}
                            placeholder="Ex: Ana Maria da Silva"
                            type="text"
                            errorText={nameErrors[index]}
                            title={`Nome do familiar ${index + 1}:`}
                            titleStyle={{
                                color: theme.palette.primaryColor,
                                fontSize: "14px",
                            }}
                            inputStyle={{
                                backgroundColor: theme.palette.primaryLowOpacity,
                            }}
                        />
                        <DeleteFamilyNameContainer onClick={() => {
                            setNewRsvp({
                                ...newRsvp,
                                names: newRsvp.names.filter((_, i) => i !== index)
                            })
                        }}>
                            <TrashIcon src={trash} />
                        </DeleteFamilyNameContainer>
                    </FamilyNameInputContainer>
                )
            }
            )
        }
        return (
            <FamilyNameInputContainer>
                <EmptyFamilyNameText>
                    Toque em "Adicionar familiar" para cadastrar um novo convidado <br />
                    Todo familiar cadastrado poderá confirmar sua presença.
                </EmptyFamilyNameText>
            </FamilyNameInputContainer>
        )
    }

    const internalSaveNewRsvp = useCallback(() => {
        let hasError = false;
        if (newRsvp.inviteName === "") {
            setNameError("Nome do convidado principal é obrigatório");
            hasError = true;
        } else {
            setNameError("");
        }
        console.log(newRsvp.telephoneNumber.length);
        if (newRsvp.telephoneNumber.length < 19) {
            setNumberError("Número de telefone inválido. Insira o código do país (Ex: +55) e o DDD (Ex: 21)");
            hasError = true;
        } else {
            setNumberError("");
        }

        const newErrors = newRsvp.names.map((name) => {
            if (name === "") {
                return "Nome do familiar é obrigatório";
            }
            return "";
        });
        setNameErrors(newErrors);

        if (hasError) {
            return;
        }

        onSaveNewRsvp({
            ...newRsvp,
            telephoneNumber: removePhoneMask(newRsvp.telephoneNumber),
        });
    }, [newRsvp, onSaveNewRsvp]);

    return (
        <BackgroundContainer isVisible={isVisible}>
            <ContentContainer>
                <Title>Novo convite</Title>
                <InputContainer>
                    <MainInput
                        value={newRsvp.inviteName}
                        onChange={(newName) => setNewRsvp({ ...newRsvp, inviteName: newName })}
                        placeholder="Ex: Mayara Matta e Família"
                        type="text"
                        errorText={nameError}
                        title="Nome no convite: "
                        titleStyle={{
                            color: theme.palette.primaryColor,
                            fontSize: "14px",
                        }}
                        inputStyle={{
                            backgroundColor: theme.palette.primaryLowOpacity,
                        }}
                    />
                    <MainInput
                        value={newRsvp.telephoneNumber}
                        onChange={(newNumber) => setNewRsvp({
                            ...newRsvp,
                            telephoneNumber: formatPhoneNumber(newNumber)
                        })}
                        placeholder="+55 (21) 99999-9999"
                        type="text"
                        errorText={numberError}
                        title="Número do celular (WhatsApp): "
                        titleStyle={{
                            color: theme.palette.primaryColor,
                            fontSize: "14px",
                        }}
                        inputStyle={{
                            backgroundColor: theme.palette.primaryLowOpacity,
                        }}
                    />
                </InputContainer>
                <Subtitle>Familiares</Subtitle>
                <FamilyNamesContainer>
                    {getFamilyContent()}
                    <FamilyNameButtonContainer>
                        <MainButton onClick={() => setNewRsvp(
                            {
                                ...newRsvp,
                                names: [
                                    ...newRsvp.names,
                                    ""
                                ]
                            }
                        )}> Adicionar familiar</MainButton>
                    </FamilyNameButtonContainer>
                </FamilyNamesContainer>

                <FooterInfosContainer>
                    <OutlineButton onClick={onClose}>Cancelar</OutlineButton>
                    <MainButton 
                        disabled={newRsvp.names.length === 0}
                        onClick={internalSaveNewRsvp}>Salvar</MainButton>
                </FooterInfosContainer>
            </ContentContainer>
        </BackgroundContainer>
    )
}