import styled from "styled-components";
import { SCREEN_SIZE } from "../../../../../constants/ScreenSizes";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 100%;
    max-width: 1224px;
    margin-top: 48px;
`;

export const SectionTitle = styled.h2`
    color: ${(props) => props.theme.palette.textSecondary};
    font-size: 18px;
    margin: 0;
`;

export const SectionContainer = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    max-width: 1224px;
    background-color: ${(props) => props.theme.palette.primaryColorLight};
    padding: 16px;
    border-radius: 8px;
    box-sizing: border-box;

    @media (max-width: ${SCREEN_SIZE.TABLET}px) {
        flex-direction: column;
    }
`;

export const InputContainer = styled.div`
    display: flex;
    width: 100%;
    flex: 3;
    
    @media (max-width: ${SCREEN_SIZE.TABLET}px) {
        padding: 0;
    }
`;

export const RightSection = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
    flex: 1;
    justify-content: center;
    align-items: center;
    margin-left: 16px;

    @media (max-width: ${SCREEN_SIZE.TABLET}px) {
        min-height: 150px;
        margin: 0;
    }
`;
