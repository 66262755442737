import { useMemo, useRef } from "react";
import { MainButton } from "../../../../../components/MainButton";
import { BackgroundContainer, CloseIcon, ContentContainer, EmptySpace, ModalHeader, QRCodeContainer } from "./styles";
import QRCode from "react-qr-code";
import closeIcon from "../../../../../assets/images/closeIcon.png";
import { useTheme } from "styled-components";
import html2canvas from "html2canvas";

interface ShowRsvpQrCodeModalProps {
    isVisible: boolean;
    onClose: () => void;
    rsvpId: string | null;
    rsvpName: string | null;
}

export const ShowRsvpQrCodeModal = ({ isVisible, onClose, rsvpId, rsvpName }: ShowRsvpQrCodeModalProps) => {
    const theme = useTheme();
    const domainUrl = window.location.origin;
    const qrCodeValue = useMemo(() => `${domainUrl}/rsvp/${rsvpId}`, [domainUrl, rsvpId]);
    const qrCodeRef = useRef<HTMLDivElement>(null);

    const handleSaveImage = async () => {
        if (qrCodeRef.current) {
            const canvas = await html2canvas(qrCodeRef.current);
            const image = canvas.toDataURL("image/png");

            const link = document.createElement("a");
            link.href = image;
            link.download = `QRCode_${rsvpName}.png`;
            link.click();
        }
    };

    return (
        <BackgroundContainer isVisible={isVisible}>
            <ContentContainer>
                <ModalHeader>
                    <EmptySpace />
                    <CloseIcon src={closeIcon} onClick={onClose} />
                </ModalHeader>
                <QRCodeContainer ref={qrCodeRef}>
                    <h1>{rsvpName}</h1>
                    <QRCode
                        size={256}
                        style={{
                            height: "auto",
                            maxWidth: "100%",
                            width: "100%",
                        }}
                        value={qrCodeValue}
                        viewBox={`0 0 256 256`}
                        fgColor={theme.palette.primaryColor}
                    />
                </QRCodeContainer>
                <a href={qrCodeValue}>{qrCodeValue}</a>
                <MainButton onClick={handleSaveImage}>Salvar</MainButton>
            </ContentContainer>
        </BackgroundContainer>
    );
};
