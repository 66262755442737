import React from "react";
import { BackButtonImage, Title, TitleContainer, TitleInfos } from "./styles";
import backImage from "../../../../assets/images/admin/backButton.png";

interface PageNameProps {
    pageTitle: string;
    onBackClick: () => void;
}

export const PageName = ({ pageTitle, onBackClick }: PageNameProps) => {

    return (
        <TitleContainer>
            <BackButtonImage onClick={onBackClick} src={backImage} />
            <TitleInfos>
                <small>Você está em: </small>
                <Title>{pageTitle}</Title>
            </TitleInfos>
        </TitleContainer>
    )

}