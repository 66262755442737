import React from "react";
import logo from "../../../assets/images/smallLogo.png";
import { ButtonGroup, ContainerBackground, InfoContainer, MjLogo, Title } from "./styles";
import { useNavigate } from "react-router-dom";
import { GENERAL_ROUTES } from "../../../constants/routes";
import { MainButton } from "../../../components/MainButton";
import { OutlineButton } from "../../../components/OutlineButton";

export const AdminMainOptions = () => {

    const navigate = useNavigate();

    return (
        <ContainerBackground>
            <InfoContainer>
                <MjLogo src={logo} alt="logo" />
                <Title>Área do Administrador</Title>
                <small>Selecione uma opção:</small>
                <ButtonGroup>
                    <MainButton secondary onClick={() => { navigate(GENERAL_ROUTES.ADMIN_GUEST_MANAGER.route) }}>Gestão de convidados</MainButton>
                    <OutlineButton secondary onClick={() => { navigate(GENERAL_ROUTES.HOME.route) }}>Início</OutlineButton>
                </ButtonGroup>
            </InfoContainer>
        </ContainerBackground>
    );
}