import { GuestsFamily } from "../types/api/invitedFamily";
import { jsPDF } from "jspdf";
import autoTable from "jspdf-autotable"; // Importe a extensão corretamente

export const formatPhoneNumber = (value: string) => {
    const cleaned = value.replace(/\D/g, ""); // Remove tudo que não for número
    const match = cleaned.match(/^(\d{2})(\d{2})(\d{5})(\d{4})$/);
    if (match) {
        return `+${match[1]} (${match[2]}) ${match[3]}-${match[4]}`;
    }
    return value;
};

export const removePhoneMask = (value: string) => value.replace(/\D/g, "");

export const generatePdfExportation = (rsvps: GuestsFamily[]) => {
    const getPtBrStatus = (status: string) => {
      switch (status) {
        case "confirmed":
          return "Confirmado";
        case "declined":
          return "Recusado";
        case "pending":
        default:
          return "Pendente";
      }
    };
  
    const pdf = new jsPDF();
    const columns = ["Nome", "Status"];
    const rows: string[][] = [];
  
    for (const family of rsvps) {
      for (const guest of family.names) {
        rows.push([guest.name, getPtBrStatus(guest.status)]);
      }
    }
  
    rows.sort((a, b) => a[0].localeCompare(b[0], "pt-BR"));
  
    pdf.text("Lista de convidados", 105, 10, { align: "center" });
  
    autoTable(pdf, {
      head: [columns],
      body: rows,
      startY: 20,
      headStyles: {
        fillColor: [84, 88, 47],
        textColor: 255,
        fontStyle: "bold",
      },
    });
  
    pdf.save("lista-de-convidados.pdf");
  };