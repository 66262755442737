import styled from "styled-components";
import { GuestName } from "../../../../../types/api/invitedFamily";
import { SCREEN_SIZE } from "../../../../../constants/ScreenSizes";

export const CardContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 16px;
    box-sizing: border-box;
    border-radius: 8px;
    background-color: ${(props) => props.theme.palette.text};
`;

export const TopInfosContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    margin-bottom: 10px;

    @media (max-width: ${SCREEN_SIZE.TABLET}px) {
        flex-direction: column;
        gap: 10px;
    }
`;

export const GuestHeaderInfoContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

export const GuestButtonGroupContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 50%;
    min-width: 200px;

    button {
        font-family: "inter";
        font-size: 16px;
    }
`;

export const InformativeText = styled.small`
    ${(props) => props.theme.typography.secondaryText};
    color: ${(props) => props.theme.palette.tertiaryColorLight};
`;

export const GuestInfoText = styled.p`
    color: ${(props) => props.theme.palette.secondaryColor};
    margin: 0;
    margin-bottom: 5px;
    font-family: "inter-bold";
    font-size: 22px;
`;


export const BottomInfosContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 10px;
`;

export const TableContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 5px;
    width: 100%;
    gap: 2px;
`;

export const FamilyRowContainer = styled.div<RowProps>`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 4px;
    box-sizing: border-box;
    height: 40px;
    border-top: ${(props) => props.index === 0 ? `1px solid ${props.theme.palette.primaryColorLight}` : "none"};
    border-bottom: ${(props) => `1px solid ${props.theme.palette.primaryColorLight}`};
`;

export const FamilyNameText = styled.p<StatusProps>`
    ${(props) => props.theme.typography.secondaryText};
    color: ${(props) => props.status === "declined" ?
        props.theme.palette.tertiaryColorLight :
        props.theme.palette.primaryColor};
    font-size: 20px;
    margin: 0;
    width: 100%;
`;

export const StatusContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: start;
    justify-content: flex-start;
    gap: 5px;
    min-width: 150px;
`;

export const BinContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    cursor: pointer;
    transition: transform 0.2s;

    &:hover {
        transform: scale(1.1);
    }
`;


export const StatusIcon = styled.img`
    width: 20px;
    height: 20px;
    object-fit: cover;
`;

export const QRCodeIcon = styled.img`
    width: 30px;
    height: 30px;
    object-fit: cover;
    cursor: pointer;
    transition: transform 0.2s;
    margin-bottom: 5px;

    &:hover {
        transform: scale(1.1);
    }
`;

export const RsvpNameContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
`;

export interface RowProps {
    index: number;
    status: GuestName["status"];
}

export interface StatusProps {
    status: GuestName["status"];
}