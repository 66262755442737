import styled from "styled-components";

export const ContainerBackground = styled.div`
    background-color: ${(props) => props.theme.palette.adminBackground};
    width: 100vw;
    min-height: 100vh;
    display: flex;
    align-items: center;
    flex-direction: column;
    box-sizing: border-box;
    color: ${(props) => props.theme.palette.text};
    padding-left: 10%;
    padding-right: 10%;
    min-width: 320px;
`;

export const CardTitle = styled.h2`
    ${(props) => props.theme.typography.secondaryTitle};
    font-size: 20px;
    text-align: center;
    color: ${(props) => props.theme.palette.secondaryColor};
`;

export const InfoRowContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
`;

export const TextDescription = styled.p`
    ${(props) => props.theme.typography.secondaryText};
    text-align: center;
    color: ${(props) => props.theme.palette.text};
    font-size: 18px;
    margin: 10px 0;
`;

export const SummaryInfos = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 1224px;
    align-items: flex-start;
    margin-top: 30px;
    flex: 1;
`;

export const CardContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    width: 100%;
    max-width: 1224px;
    min-height: 60vh;
    margin-bottom: 30px;
`;

export const InviteNotFoundText = styled.p`
    ${(props) => props.theme.typography.secondaryText};
    color: ${(props) => props.theme.palette.text};
    text-align: center;
    font-size: 24px;
    margin-top: 48px;
`;
