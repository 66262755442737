import styled from "styled-components";

export const BackgroundContainer = styled.div<BackgroundContainerProps>`
    width: 100vw;
    height: 100%;
    background: ${(props) => props.theme.palette.modalBackground};
    display: ${(props) => props.isVisible ? "flex" : "none"};
    position: fixed;
    z-index: 100;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
`;

export const ContentContainer = styled.div`
    width: 90%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${(props) => props.theme.palette.text};
    gap: 8px;
    border-radius: 8px;
    padding: 16px;
    box-sizing: border-box;
    flex-direction: column;
    max-width: 700px;
`;

export const Title = styled.h1`
    ${(props) => props.theme.typography.primaryTitle};
    font-size: 32px;
    text-align: center;
    margin-top: 16px;
    margin-bottom: 0;
    color: ${(props) => props.theme.palette.secondaryColor};
`;

export const Subtitle = styled.h2`
    ${(props) => props.theme.typography.secondaryTitle};
    font-size: 20px;
    text-align: left;
    width: 100%;
    margin-top: 16px;
    margin-bottom: 0;
    padding-left: 8px;
    box-sizing: border-box;
    color: ${(props) => props.theme.palette.primaryColor};
`;

export const InputContainer = styled.div`
    display: flex;
    width: 100%;
    border-radius: 8px;
    padding: 8px;
    flex-direction: column;
    gap: 8px;
`;

export const FamilyNamesContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 100%;
    max-height: 300px;
    overflow-y: auto;

    ::-webkit-scrollbar {
        width: 6px;
    }

    ::-webkit-scrollbar-thumb {
        background-color: ${(props) => props.theme.palette.tertiaryColorLight};
        border-radius: 8px;
    }
`;

export const FamilyNameButtonContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    width: 100%;

    button {
        max-width: 30%;
        margin-top: 16px;
        margin-bottom: 24px;
        margin-right: 16px;
        background-color: ${(props) => props.theme.palette.tertiaryColorLight};
    }
`;

export const FooterInfosContainer = styled.div`
    display: flex;
    gap: 16px;
    justify-content: flex-end;
    width: 100%;

    button {
        min-width: 150px;
    }
`;

export const FamilyNameInputContainer = styled.div`
    display: flex;
    gap: 8px;
    flex-direction: row;
    padding-left: 16px;
    padding-right: 16px;
    box-sizing: border-box;
`;

export const EmptyFamilyNameText = styled.p`
    ${(props) => props.theme.typography.secondaryText};
    color: ${(props) => props.theme.palette.tertiaryColorLight};
    width: 100%;
    text-align: center;
    margin-top: 16px;
`;

export const DeleteFamilyNameContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    background-color: ${(props) => props.theme.palette.primaryLowOpacity};
    width: 56px;
    border-radius: 8px;
    margin-top: 30px;

    :hover {
        opacity: 0.8;
    }
`;

export const TrashIcon = styled.img`
    width: 24px;
`;


interface BackgroundContainerProps {
    isVisible: boolean;
}