import React from "react"
import { Header } from "../../../../components/Header"
import { useNavigate, useParams } from "react-router-dom";
import { GODPARENTS_BY_ID, MIRINHOS_ID } from "../../../../constants/GodParents";
import { ContainerBackground, Description, InfosContainer, Title } from "./styles";
import { MainButton } from "../../../../components/MainButton";
import { GENERAL_ROUTES } from "../../../../constants/routes";

export const PreGameInfos = () => {

    const { id } = useParams<{ id: string }>();
    const navigate = useNavigate();
    const currentGodparents = GODPARENTS_BY_ID[id as keyof typeof GODPARENTS_BY_ID ?? "1"];

    const getGodparentName = () => {
        if (currentGodparents.godfather && currentGodparents.godmother) {
            return currentGodparents.godfather + " e " + currentGodparents.godmother;
        } else if (currentGodparents.godfather) {
            return currentGodparents.godfather;
        }
        return currentGodparents.godmother;
    }

    const getDescription = () => {
        if(id === MIRINHOS_ID) {
            return (
                <>
                    <Description>Mirinho, você é uma pessoa que nós admiramos muito! Sempre de bom humor, com um jeito leve de encarar a vida e um talento especial para unir a família. Tenho lembranças de você tentando ganhar de mim no PlayStation 2 e dos rolês no civic. Você sempre trouxe e trás alegria para qualquer ambiente. </Description>
                    <Description>Lorena, desde o começo, você nos recebeu com muito carinho e dedicação. Seu jeito acolhedor e atencioso faz qualquer visita ser especial. Obrigado por sempre nos receber com tanto apreço.</Description>
                    <Description>Juntos, vocês formam um casal inspirador: jovens, animados e sempre prontos para ajudar. São aquele tipo de gente que a queremos ter por perto, que faz qualquer momento ficar mais leve e especial. E para manter vocês ainda mais perto, temos uma missão especial. Para concluí-la, precisamos garantir que possuem os requisitos necessários. Chegou a hora de testá-los.</Description>
                    <Description>Estão prontos?</Description>
                </>
            )
        }

        if (currentGodparents.godfather && currentGodparents.godmother) {
            return (
                <>
                    <Description>A vida é feita de desafios, e cada etapa importante traz seus obstáculos. Mas, assim como em todas as jornadas, ter amigos ao lado faz toda a diferença. Vocês foram escolhidos porque estiveram conosco nos momentos mais felizes e nas dificuldades, mostrando que a amizade é um dos maiores pilares da nossa história.</Description>
                    <Description>Agora, temos uma missão especial para vocês. E, para garantir que possuem os requisitos necessários para essa tarefa, chegou a hora de testá-los.</Description>
                    <Description>Estão prontos?</Description>
                </>
            )
        }

        return (
            <>
                <Description>A vida é feita de desafios, e cada etapa importante traz seus obstáculos. Mas, assim como em todas as jornadas, ter amigos ao lado faz toda a diferença. Você foi escolhido porque esteve conosco nos momentos mais felizes e nas dificuldades, mostrando que a amizade é um dos maiores pilares da nossa história.</Description>
                <Description>Agora, temos uma missão especial para você. E, para garantir que possui os requisitos necessários para essa tarefa, chegou a hora de testá-lo.</Description>
                <Description>Está pronto?</Description>
            </>
        )
    }

    return (
        <ContainerBackground>
            <Header />
            <InfosContainer>
                <Title>{getGodparentName()}</Title>
                {getDescription()}
                <MainButton secondary onClick={() => navigate(GENERAL_ROUTES.GODPARENTS_INVITATION_GAME.route + "/" + id)}>ACEITAR DESAFIO</MainButton>
            </InfosContainer>
        </ContainerBackground>
    )
}