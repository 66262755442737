import styled from "styled-components";

export const BackgroundContainer = styled.div<BackgroundContainerProps>`
    width: 100vw;
    height: 100%;
    background: ${(props) => props.theme.palette.modalBackground};
    display: ${(props) => props.isVisible ? "flex" : "none"};
    position: fixed;
    z-index: 100;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
`;

export const ContentContainer = styled.div`
    width: 40%;
    min-width: 500px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${(props) => props.theme.palette.text};
    gap: 8px;
    border-radius: 8px;
    padding: 16px;
    box-sizing: border-box;
    flex-direction: column;
    max-width: 700px;

    button {
        width: 30%;
        margin-top: 16px;
        min-width: 200px;
    }
`;

export const ModalHeader = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
`;

export const CloseIcon = styled.img`
    width: 32px;
    height: 32px;
    cursor: pointer;
`;

export const QRCodeContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    max-width: 300px;
    height: 300px;
    margin-top: -32px;

    h1 {
        ${(props) => props.theme.typography.primaryTitle};
        color: ${(props) => props.theme.palette.secondaryColor};
        font-size: 26px;
        margin-bottom: 24px;
    }
`;

export const EmptySpace = styled.div`
    width: 32px;
`;

interface BackgroundContainerProps {
    isVisible: boolean;
}