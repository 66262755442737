import styled from "styled-components";

export const ContainerBackground = styled.div`
    background-color: ${(props) => props.theme.palette.primaryColor};
    width: 100vw;
    min-height: 100dvh;
    display: flex;
    align-items: center;
    padding-top: 90px;
    flex-direction: column;
    box-sizing: border-box;
    color: ${(props) => props.theme.palette.text};
`;


export const Title = styled.h1`
    ${(props) => props.theme.typography.primaryTitle};
    font-size: 32px;
    margin-bottom: 10px;
    text-align: center;
`;

export const Description = styled.p`
    ${(props) => props.theme.typography.primaryText};
    font-size: 18px;
    text-align: center;
    margin: 0 20px;
`;

export const InfosContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 32px;
    max-width: 500px;

    button {
        max-width: 80%;
        height: 50px;
        margin-bottom: 60px;
    }
`;
