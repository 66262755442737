import styled from "styled-components";

export const TitleInfos = styled.div`
    flex-direction: column;

    small {
        color: ${(props) => props.theme.palette.textSecondary};
        font-size: 14px;
    }
`;

export const Title = styled.h1`
    ${(props) => props.theme.typography.primaryTitle};
    font-size: 24px;
    text-align: center;
    color: ${(props) => props.theme.palette.text};
    margin: 0;
`;

export const BackButtonImage = styled.img`
    height: 30px;
    object-fit: contain;
    cursor: pointer;

    transition: transform 0.2s;
    :hover {
        transform: scale(1.1);
    }
`;

export const TitleContainer = styled.div`
    display: flex;
    width: 100%;
    align-items: flex-end;
    gap: 10px;
    margin-top: 20px;
`;
