import styled from "styled-components";

export const ContainerBackground = styled.div`
    background-color: ${(props) => props.theme.palette.adminBackground};
    background: linear-gradient(45deg, ${(props) => props.theme.palette.secondaryColor} 0%, ${(props) => props.theme.palette.adminBackground} 100%);
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    flex-direction: column;
    box-sizing: border-box;
    color: ${(props) => props.theme.palette.text};
    justify-content: center;
    align-items: center;
`;

export const InfoContainer = styled.div`
    background-color: ${(props) => props.theme.palette.primaryColor};
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 80%;
    max-width: 500px;
    min-width: 200px;
    box-sizing: border-box;
    padding: 32px;
    border-radius: 16px;

    small {
        font-size: 14px;
        color: ${(props) => props.theme.palette.textSecondary};
        margin-top: 16px;
    }
`;

export const Title = styled.h1`
    font-size: 28px;
    text-align: center;
    color: ${(props) => props.theme.palette.text};
`;

export const ButtonGroup = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-top: 16px;
`;

export const MjLogo = styled.img`
    width: 80px;
`;

export const CardTitle = styled.h2`
    ${(props) => props.theme.typography.secondaryTitle};
    font-size: 20px;
    text-align: center;
    color: ${(props) => props.theme.palette.secondaryColor};
`;

