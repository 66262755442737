import React from "react";
import { GuestsFamily } from "../../../../../../types/api/invitedFamily";
import { DescriptionText, InfoContainer, SectionTitle, ShowResultContainer } from "./styles";
import { OutlineButton } from "../../../../../../components/OutlineButton";
import { MainButton } from "../../../../../../components/MainButton";
import { GuestFamilyCard } from "./components/GuestFamilyCard";
import { GUEST_ERROR_WHATSAPP_URL } from "../../../../../../constants/ContactUrls";

interface GuestResultListProps {
    resultList: GuestsFamily[];
    onNewSearchRequested: () => void;
    onSelected: (guest: GuestsFamily) => void;
}

export const GuestResultList = ({resultList, onNewSearchRequested, onSelected}: GuestResultListProps) => {
   return (
        <InfoContainer>
            <SectionTitle>Encontramos {resultList.length === 1 ? " 1 convite" : resultList.length + " convites"} com esse nome</SectionTitle>
                <DescriptionText>Toque no convite para confirmar a presença de cada convidado individualmente.</DescriptionText>
                <ShowResultContainer>
                    {resultList.map((guest) => (
                        <GuestFamilyCard key={guest._id} guestInfos={guest} onSelected={() => onSelected(guest)}/>
                    ))}
                </ShowResultContainer>
                
                <DescriptionText>Não encontrou seu nome? Veja outras opções: </DescriptionText>
                <OutlineButton secondary onClick={onNewSearchRequested}>Nova busca</OutlineButton>
                <MainButton secondary onClick={() => window.location.href = GUEST_ERROR_WHATSAPP_URL}>Falar com os noivos</MainButton>
        </InfoContainer>
    )
}