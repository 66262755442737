import React from "react";
import { ButtonBackground, LoaderContainer } from "./styles";
import ContentLoader from "react-content-loader";
import { useTheme } from "styled-components";

interface MainButtonProps {
    children: React.ReactNode;
    secondary?: boolean;
    onClick: () => void;
    isLoading?: boolean;
    disabled?: boolean;
}

export const MainButton = ({ children, onClick, secondary, isLoading, disabled = false }: MainButtonProps) => {

    const currentTheme = useTheme();

    if (isLoading) {
        return (
            <LoaderContainer>
                <ContentLoader
                    speed={2}
                    width={300}
                    height={50}
                    viewBox="0 0 300 50"
                    backgroundColor={currentTheme.palette.primaryColor}
                    foregroundColor={currentTheme.palette.primaryColorLight}
                >
                    <rect x="9" y="7" rx="3" ry="3" width="300" height="274" />
                </ContentLoader>
            </LoaderContainer>
        );
    }

    return (
        <ButtonBackground onClick={() => !disabled && onClick()} secondary={secondary} disabled={disabled}>
            {children}
        </ButtonBackground>
    );
}