import React from "react";
import { ConfirmButtonContainer, ContainerBackground, DescriptionText, InfoContainer, SectionTitle } from "./styles";
import { useNavigate, useParams } from "react-router-dom";
import { useRsvp } from "./hooks/useRsvp";
import { Header } from "../../../../components/Header";
import ContentLoader from "react-content-loader";
import { useTheme } from "styled-components";
import { OutlineButton } from "../../../../components/OutlineButton";
import { GENERAL_ROUTES } from "../../../../constants/routes";
import { GuestCard } from "./components/GuestCard";
import { MainButton } from "../../../../components/MainButton";
import { GUEST_ERROR_WHATSAPP_URL } from "../../../../constants/ContactUrls";
import { EndSectionLine } from "../../../../components/EndSectionLine";

export const Rsvp = () => {
    const { id } = useParams<{ id: string }>();
    const { currentRsvp, updateGuestNameState, confirmRsvp } = useRsvp(id);
    const currentTheme = useTheme();
    const navigate = useNavigate();

    const onNewSearchRequested = () => {
        navigate(GENERAL_ROUTES.RSVP.route);
    };

    const LoadingContent = () => (
        <ContentLoader
            speed={1}
            width={300}
            height={150}
            viewBox="0 0 300 150"
            backgroundColor={currentTheme.palette.emptyGreen}
            foregroundColor={currentTheme.palette.imageMask}
        >
            <rect x="9" y="7" rx="3" ry="3" width="290" height="40" />
            <rect x="9" y="57" rx="3" ry="3" width="290" height="40" />
            <rect x="9" y="107" rx="3" ry="3" width="290" height="40" />
        </ContentLoader>
    );

    return (
        <ContainerBackground>
            <Header />
            <InfoContainer>
                <SectionTitle>CONFIRMAÇÃO DE PRESENÇA</SectionTitle>
                {!currentRsvp ? (
                    <LoadingContent />
                ) : (
                    <>
                        <DescriptionText>
                            Sua presença é muito importante para nós. Avise-nos se poderá participar.
                        </DescriptionText>
                        {currentRsvp.names.map((guestInfos) => (
                            <GuestCard
                                key={guestInfos._id}
                                guestInfos={guestInfos}
                                onChangeGuestState={updateGuestNameState}
                            />
                        ))}
                        <DescriptionText>
                            Toque em "Sim" ou "Não" para selecionar quem poderá ir ao casamento e depois
                            toque em confirmar para salvar sua escolha.
                        </DescriptionText>
                        <ConfirmButtonContainer>
                            <MainButton onClick={confirmRsvp}>Confirmar</MainButton>
                        </ConfirmButtonContainer>
                        <EndSectionLine color="terracota" />
                        <DescriptionText>Não encontrou seu nome? Veja outras opções:</DescriptionText>
                        <OutlineButton onClick={onNewSearchRequested}>Buscar por nome</OutlineButton>
                        <MainButton onClick={() => (window.location.href = GUEST_ERROR_WHATSAPP_URL)}>
                            Falar com os noivos
                        </MainButton>
                    </>
                )}
            </InfoContainer>
        </ContainerBackground>
    );
};