import { useEffect, useMemo, useState } from "react";
import { createRsvp, deleteRsvp, getAllRsvps, updateRsvp } from "../../../../services/api/rsvp";
import { CreateRsvpRequest, GuestName, GuestsFamily } from "../../../../types/api/invitedFamily";
import toast from "react-hot-toast";

export const useAdminRsvp = () => {
    const [rsvps, setRsvps] = useState<GuestsFamily[]>([]);
    const [filteredRsvps, setFilteredRsvps] = useState<GuestsFamily[]>([]);
    const [filterType, setFilterType] = useState<"all" | GuestName["status"]>("all");
    const rsvpByName = useMemo(() => rsvps.map((rsvp) => rsvp.names), [rsvps]);
    const confirmedCounter = useMemo(() => rsvpByName.flat().filter((name) => name.status === "confirmed").length, [rsvpByName]);
    const declinedCounter = useMemo(() => rsvpByName.flat().filter((name) => name.status === "declined").length, [rsvpByName]);
    const pendingCounter = useMemo(() => rsvpByName.flat().filter((name) => name.status === "pending").length, [rsvpByName]);
    const totalCounter = useMemo(() => rsvpByName.flat().length, [rsvpByName]);

    useEffect(() => {
        getAllRsvps()
            .then((data) => {
                const sortedData = data.sort((a, b) => a.inviteName.localeCompare(b.inviteName));
                setRsvps(sortedData);
                setFilteredRsvps(sortedData); // Inicializa com todos os RSVPs
            })
            .catch((error) => {
                console.error("Error on get all RSVPs", error);
                toast.error("Erro ao buscar os RSVPs");
            });
    }, []);

    useEffect(() => {
        if (filterType === "all") {
            setFilteredRsvps(rsvps);
        } else {
            setFilteredRsvps(
                rsvps.filter((rsvp) => rsvp.names.some((name) => name.status === filterType))
            );
        }
    }, [filterType, rsvps]);

    const removeFamilyMember = (rsvp: GuestsFamily, familyMemberId: string) => {
        const loadingToast = toast.loading("Removendo convidado...");
        const updatedRsvp = { ...rsvp, names: rsvp.names.filter((name) => name._id !== familyMemberId) };
        if(updatedRsvp.names.length === 0) {
            deleteRsvp(updatedRsvp._id)
                .then(() => {
                    toast.success("Convite removido com sucesso")
                    setRsvps(rsvps.filter((rsvp) => rsvp._id !== updatedRsvp._id));
                    setFilteredRsvps(filteredRsvps.filter((rsvp) => rsvp._id !== updatedRsvp._id));
                }).catch(() => toast.error("Erro ao remover convite"))
                .finally(() => toast.dismiss(loadingToast));
            return;
        }
        updateRsvp(updatedRsvp)
            .then(() => {
                toast.success("Membro da família removido com sucesso")
                setRsvps(rsvps.map((rsvp) => rsvp._id === updatedRsvp._id ? updatedRsvp : rsvp));
                setFilteredRsvps(filteredRsvps.map((rsvp) => rsvp._id === updatedRsvp._id ? updatedRsvp : rsvp));
            }).catch(() => toast.error("Erro ao remover membro da família"))
            .finally(() => toast.dismiss(loadingToast));
    };

    const saveNewRsvp = (newRsvp: CreateRsvpRequest) => {
        const loadingToast = toast.loading("Salvando novo RSVP...");
        createRsvp(newRsvp)
            .then((savedRsvp) => {
                toast.success("Novo RSVP salvo com sucesso");
                setRsvps([...rsvps, savedRsvp]);
                setFilteredRsvps([...filteredRsvps, savedRsvp]);
            }).catch(() => toast.error("Erro ao salvar novo RSVP"))
            .finally(() => toast.dismiss(loadingToast));
    };

    const deleteRsvpById = (id: string) => {
        const loadingToast = toast.loading("Removendo convite...");
        deleteRsvp(id)
            .then(() => {
                toast.success("Convite removido com sucesso");
                setRsvps(rsvps.filter((rsvp) => rsvp._id !== id));
                setFilteredRsvps(filteredRsvps.filter((rsvp) => rsvp._id !== id));
            }).catch(() => toast.error("Erro ao remover convite"))
            .finally(() => toast.dismiss(loadingToast));
    }

    return {
        rsvps: filteredRsvps,
        filterType,
        confirmedCounter,
        declinedCounter,
        pendingCounter,
        totalCounter,
        setFilterType,
        removeFamilyMember,
        saveNewRsvp,
        deleteRsvpById
    };
};
