import styled from "styled-components";
import { SCREEN_SIZE } from "../../../../../constants/ScreenSizes";


export const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 100%;
    max-width: 1224px;
    margin-top: 48px;
`;

export const SectionTitle = styled.h2`
    color: ${(props) => props.theme.palette.textSecondary};
    font-size: 18px;
    margin: 0;
`;

export const HeaderContainer = styled.div`
    display: flex;
    gap: 8px;
    width: 100%;
    justify-content: center;

    @media (max-width: ${SCREEN_SIZE.TABLET}px) {
        flex-direction: column;
        align-items: center;
    }
`;

export const BoxContainer = styled.div<BoxHeaderProps>`
    display: flex;
    flex-direction: column;
    background-color: ${(props) => props.theme.palette.primaryColorLight};
    border-radius: 8px;
    width: 100%;
    height: 200px;
    min-width: 150px;
    max-width: 300px;
    border: ${(props) => props.isCanceled ? `2px solid ${props.theme.palette.tertiaryColorLight}` : 'none'};
    box-sizing: border-box;
    transition: transform 0.2s;

    :hover {
        transform: scale(1.05);
    }
`;

interface BoxHeaderProps {
    isCanceled?: boolean;
}

export const BoxHeader = styled.div`
    display: flex;
    flex-direction: row;
    padding: 8px;
    gap: 8px;
    align-items: center;

    img {
        width: 16px;
        height: 16px;
        object-fit: contain;
    }

    h3 {
        margin: 0;
        color: ${(props) => props.theme.palette.textSecondary};
        font-size: 18px;
    }
`;

export const ValueInfoText = styled.p`
    color: ${(props) => props.theme.palette.text};
    font-size: 78px;
    margin: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`;