import { CardContainer, ContainerBackground, InfoRowContainer, SummaryInfos, TextDescription, InviteNotFoundText } from "./styles";
import { CheckBox } from "../../../components/CheckBox";
import { useAdminRsvp } from "./hooks/useAdminRsvp";
import { AdminRsvpCard } from "./components/AdminRsvpCard";
import { ActionSection } from "./components/ActionsSection";
import { useWppMessage } from "./hooks/useWppMessage";
import { useState } from "react";
import { NewRsvpModal } from "./components/NewRsvpModal";
import { PageName } from "../components/PageName";
import { HeaderRsvpSection } from "./components/HeaderRsvpSection";
import { CreateRsvpRequest, GuestsFamily } from "../../../types/api/invitedFamily";
import { generatePdfExportation } from "../../../utils/TextFormatter";
import toast from "react-hot-toast";
import { ShowRsvpQrCodeModal } from "./components/ShowRsvpQrCodeModal";
import { useNavigate } from "react-router-dom";
import { GENERAL_ROUTES } from "../../../constants/routes";
import { CheckDeleteOptionModal } from "./components/CheckDeleteOptionModal";

export const AdminGuestManager = () => {

    const {
        rsvps,
        filterType,
        confirmedCounter,
        declinedCounter,
        pendingCounter,
        totalCounter,
        setFilterType,
        saveNewRsvp,
        removeFamilyMember,
        deleteRsvpById
    } = useAdminRsvp();
    const { sendWppMessage, wppMessage, setWppMessage } = useWppMessage();
    const [newRsvpModalVisible, setNewRsvpModalVisible] = useState(false);
    const [showQrCodeModalRsvp, setShowQrCodeModalRsvp] = useState<GuestsFamily | null>(null);
    const [transactionToDelete, setTransactionToDelete] = useState<GuestsFamily | null>(null);
    const navigate = useNavigate();

    return (
        <ContainerBackground>
            <PageName pageTitle="Gestão de Convidados" onBackClick={() => navigate(GENERAL_ROUTES.ADMIN_MAIN_OPTIONS.route)} />
            <HeaderRsvpSection
                totalGuests={totalCounter}
                pendingGuests={pendingCounter}
                confirmedGuests={confirmedCounter}
                canceledGuests={declinedCounter}
            />

            <ActionSection
                wppMessage={wppMessage}
                setWppMessage={setWppMessage}
                onAddNewGuest={() => setNewRsvpModalVisible(true)}
                onExportList={() => {
                    const toastRef = toast.loading("Gerando lista de convidados...");
                    generatePdfExportation(rsvps)
                    setTimeout(() => {
                        toast.dismiss(toastRef);
                    }, 3000);
                }}
            />

            <SummaryInfos>
                <InfoRowContainer>
                    <TextDescription>Filtrar por: </TextDescription>
                    <CheckBox
                        label="Todos"
                        checked={filterType === "all"}
                        onChange={() => setFilterType("all")}
                    />
                    <CheckBox
                        label="Recusados"
                        checked={filterType === "declined"}
                        onChange={() => setFilterType("declined")}
                    />
                    <CheckBox
                        label="Pendentes"
                        checked={filterType === "pending"}
                        onChange={() => setFilterType("pending")}
                    />
                </InfoRowContainer>
            </SummaryInfos>
            <CardContainer>
                {
                    rsvps.length === 0 ? <InviteNotFoundText>Nenhum convite encontrado!</InviteNotFoundText> :
                        rsvps.map((rsvp) => (
                            <AdminRsvpCard
                                key={rsvp._id}
                                rsvpInfos={rsvp}
                                onSendMessage={() => sendWppMessage(rsvp)}
                                onDeleteRsvp={() => setTransactionToDelete(rsvp)}
                                onQrCodeClick={() => setShowQrCodeModalRsvp(rsvp)}
                                onFamilyMemberDelete={(familyMemberId) => removeFamilyMember(rsvp, familyMemberId)}
                            />
                        ))
                }
            </CardContainer>

            <NewRsvpModal
                isVisible={newRsvpModalVisible}
                onClose={() => setNewRsvpModalVisible(false)}
                onSaveNewRsvp={(newRsvp: CreateRsvpRequest) => {
                    saveNewRsvp(newRsvp);
                    setNewRsvpModalVisible(false);
                }}
            />

            <ShowRsvpQrCodeModal
                isVisible={showQrCodeModalRsvp !== null}
                onClose={() => setShowQrCodeModalRsvp(null)}
                rsvpId={showQrCodeModalRsvp?._id || null}
                rsvpName={showQrCodeModalRsvp?.inviteName || ""}
            />

            <CheckDeleteOptionModal
                isVisible={transactionToDelete !== null}
                onCancel={() => setTransactionToDelete(null)}
                onConfirmDelete={() => {
                    deleteRsvpById(transactionToDelete?._id || "");
                    setTransactionToDelete(null);
                }}
            />
        </ContainerBackground>
    );
}