import React, { useEffect } from 'react';
import { MainRoutes } from './routes';
import { ThemeProvider } from 'styled-components';
import { DarkTheme } from './constants/DarkTheme';
import { Toaster } from 'react-hot-toast';
import { useAuthContext } from './store/useAuthContext';
import { Analytics } from "@vercel/analytics/react"

function App() {

  const initializeAuth = useAuthContext((state) => state.initializeAuth)

  useEffect(() => {
    initializeAuth()
  }, [initializeAuth])

  return (
    <ThemeProvider theme={DarkTheme}>
      <Toaster position='top-center' />
      <Analytics />
      <MainRoutes />
    </ThemeProvider>
  );
}

export default App;
