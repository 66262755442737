import { MainButton } from "../../../../../components/MainButton";
import { BackgroundContainer, ButtonContainer, ContentContainer, ModalHeader } from "./styles";
import { OutlineButton } from "../../../../../components/OutlineButton";

interface CheckDeleteOptionModalProps {
    isVisible: boolean;
    onCancel: () => void;
    onConfirmDelete: () => void;
}

export const CheckDeleteOptionModal = ({ isVisible, onCancel, onConfirmDelete }: CheckDeleteOptionModalProps) => {

    return (
        <BackgroundContainer isVisible={isVisible}>
            <ContentContainer>
                <ModalHeader>
                    <h2>Tem certeza que deseja excluir o convite?</h2>
                </ModalHeader>
                <ButtonContainer>
                    <OutlineButton onClick={onCancel}>Não, me tire daqui!</OutlineButton>
                    <MainButton onClick={onConfirmDelete}>Sim, excluir!</MainButton>
                </ButtonContainer>
            </ContentContainer>
        </BackgroundContainer>
    );
};
