import React from "react";
import { Container, InputContainer, RightSection, SectionContainer, SectionTitle } from "./styles";
import MainInput from "../../../../../components/MainInput";
import { MainButton } from "../../../../../components/MainButton";
import { OutlineButton } from "../../../../../components/OutlineButton";
import { useTheme } from "styled-components";

interface ActionSectionProps {
    wppMessage: string;
    setWppMessage: (newMessage: string) => void;
    onAddNewGuest: () => void;
    onExportList: () => void;
}

export const ActionSection = ({wppMessage, setWppMessage, onAddNewGuest, onExportList}: ActionSectionProps) => {

    const theme = useTheme();

    return (
        <Container>
            <SectionTitle>Ações</SectionTitle>
            <SectionContainer>
                <InputContainer>
                    <MainInput
                        value={wppMessage}
                        onChange={(newMessage) => setWppMessage(newMessage)}
                        placeholder="Digite a mensagem que será enviada via whatsapp. O link correspondente será anexado automaticamente ao final da mensagem."
                        type="text"
                        rowsNumber={2}
                        title="Mensagem para o convidado (WhatsApp): "
                        titleStyle={{ 
                            marginTop: 0,
                            color: theme.palette.textSecondary,
                            fontSize: "14px",
                        }}
                        inputStyle={{
                            fontSize: "14px",
                            borderRadius: "16px", 
                            marginTop: "8px",
                            backgroundColor: "transparent",
                            color: theme.palette.text,
                            border: `1px solid ${theme.palette.textSecondary}`}}
                    />
                </InputContainer>
                <RightSection>
                    <MainButton secondary onClick={() => onAddNewGuest()}>Novo convidado</MainButton>
                    <OutlineButton secondary onClick={() => onExportList()}>Exportar lista</OutlineButton>
                </RightSection>
            </SectionContainer>
        </Container>

    );
}