import { BoxContainer, BoxHeader, Container, HeaderContainer, SectionTitle, ValueInfoText } from "./styles";
import count from "../../../../../assets/images/admin/count.png";
import question from "../../../../../assets/images/admin/question.png";
import xYellow from "../../../../../assets/images/admin/xYellow.png";
import checkYellow from "../../../../../assets/images/admin/checkYellow.png";

interface HeaderRsvpSectionProps {
    totalGuests: number;
    confirmedGuests: number;
    pendingGuests: number;
    canceledGuests: number;
}

export const HeaderRsvpSection = ({
    totalGuests,
    confirmedGuests,
    pendingGuests,
    canceledGuests
}: HeaderRsvpSectionProps) => {

    const InfoBox = (title: string, value: number, icon: string, isCanceled = false) => {
        return (
            <BoxContainer isCanceled={isCanceled}>
                <BoxHeader>
                    <img src={icon} alt="icon" />
                    <h3>{title}</h3>
                </BoxHeader>
                <ValueInfoText>{value}</ValueInfoText>
            </BoxContainer>
        )
    }

    return (
        <Container>
            <SectionTitle>Resumo dos convidados</SectionTitle>
            <HeaderContainer>
                {InfoBox("Total de convidados", totalGuests, count)}
                {InfoBox("Confirmados", confirmedGuests, checkYellow)}
                {InfoBox("Pendentes", pendingGuests, question)}
                {InfoBox("Cancelados", canceledGuests, xYellow, canceledGuests !== 0)}
            </HeaderContainer>
        </Container>
    );

}